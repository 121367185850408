@media print {
  body * {
    visibility: hidden;
  }
  #QRCodeContainer,
  #QRCodeContainer * {
    visibility: visible;
  }
  #QRCodeContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 256px;
    height: 256px;
  }
  #CoverLetterQRsContainer,
  #CoverLetterQRsContainer * {
    visibility: visible;
  }
  #CoverLetterQRsContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}
